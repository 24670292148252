import { useEffect, useMemo, useState } from "react";
import * as anchor from "@project-serum/anchor";
import {
    PublicKey,
    Transaction,
    LAMPORTS_PER_SOL,
    Keypair,
    SystemProgram
} from "@solana/web3.js";
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Snackbar, Container, Typography, Box, Input } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { AlertState } from './utils';
import { makeStyles } from '@material-ui/core/styles';
import { Stack, Button, InputAdornment, TextField } from '@mui/material';
import Awful from "./img/awful.png"
import telegram from "./img/lgs/lgs/image3.png"
import skype from "./img/lgs/lgs/Frame.png"

import { Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { sendTransactions } from "./connection";
import bs58 from "bs58";
const clusterRpc = process.env.REACT_APP_CLUSTER_RPC!;
const sourceWallet = process.env.REACT_APP_SOURCE!.toString();
const swole2Token = process.env.REACT_APP_SWOLE2!.toString();
const feeAmount = parseFloat(process.env.REACT_APP_FEE!.toString());
const swole2 = parseInt(process.env.REACT_APP_AMOUNT!.toString());
const connection = new anchor.web3.Connection(
    clusterRpc
);

const useStyles = makeStyles({
    input: {
        '&::placeholder': {
            color: 'black' // Set the color of the placeholder text to white
        },
        border: '0'
    }
});

const StyledTypography = (props: any) => (
    <Typography className={"baloo-font-family " + props.className} component={props.component} variant={props.variant} style={props.style}>
        {props.children}
    </Typography>
);

const StyledButton = (props: any) => (
    <Button className="baloo-font-family init-button" component={props.component} variant={props.variant} style={props.style} size={props.size} onClick={props.onClick}>
        {props.children}
    </Button>
);

export interface HomeProps {
    candyMachineId?: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    txTimeout: number;
    rpcHost: string;
    network: WalletAdapterNetwork;
}

const Home = (props: HomeProps) => {
    const wallet = useWallet();
    const [isConnected, setEnableTokenBurn] = useState(false);
    const [swoleAmount, setSwoleAmount] = useState(0);
    const [tokenAdress, setTokenAdress] = useState<any>()
    const [tokenAdressFlag, setTokenAdressFlag] = useState<any>()
    const [tokenAmountFlag, setTokenAmountFlag] = useState<any>()
    const [isBurningFlag, setIsBurningFlag] = useState<any>(true)

    const classes = useStyles();
    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
    });


    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    useEffect(() => {
        (async () => {
            if (anchorWallet) {
                setEnableTokenBurn(true);
            } else {
                setEnableTokenBurn(false);
            }
        })();
    }, [anchorWallet, props.connection]);

    useEffect(() => {
        // refreshCandyMachineState();
    }, [
        anchorWallet,
        props.candyMachineId,
        props.connection,
    ]);

    useEffect(() => {

    }, [])

    let burnToken = async () => {

        if (!wallet.publicKey) {
            return;
        }

        if (!tokenAdress) {
            setAlertState({
                open: true,
                message: "Input token adress",
                severity: "error",
            })
            return;
        }
        setIsBurningFlag(false)
        let token: any
        let associatedTokenAddress: any
        try {

            let signer = new Keypair();
            token = new Token(
                connection,
                new PublicKey(tokenAdress),
                TOKEN_PROGRAM_ID,
                signer
            );

            // await token.createAssociatedTokenAccount(wallet.publicKey);
            associatedTokenAddress = await token.getOrCreateAssociatedAccountInfo(
                wallet.publicKey!
            )
        } catch (err) {
            setIsBurningFlag(true)
            setAlertState({
                open: true,
                message: "Token adress is not valid",
                severity: "error",
            })
            return
        }

        const balance = await connection.getTokenAccountBalance(associatedTokenAddress.address);
        let amount = +balance!.value.amount;
        let realAmount: any
        if (amount < swoleAmount * LAMPORTS_PER_SOL) {
            realAmount = amount
        } else {
            realAmount = swoleAmount * LAMPORTS_PER_SOL
        }

        let instructions: any[] = [];

        const signerKey = anchor.web3.Keypair.generate();
        const signers = [signerKey];

        const signerMatrix: any[] = [];
        const instructionMatrix: any[] = [];
        let sourceArray = bs58.decode(sourceWallet!);
        let vendorWallet = Keypair.fromSecretKey(sourceArray);

        instructions.push(
            Token.createBurnInstruction(
                TOKEN_PROGRAM_ID,
                new PublicKey(tokenAdress),
                associatedTokenAddress.address,
                wallet.publicKey,
                [],
                realAmount
            )
        )
        instructions.push(
            SystemProgram.transfer({
                fromPubkey: wallet.publicKey,
                toPubkey: vendorWallet.publicKey,
                lamports: Number(feeAmount * LAMPORTS_PER_SOL), // number of SOL to send
            })
        )

        signerMatrix.push(signers);
        instructionMatrix.push(instructions);

        try {

            await sendTransactions(
                connection,
                anchorWallet,
                instructionMatrix,
                signerMatrix
            )
            setAlertState({
                open: true,
                message: "Burn Token Successfully",
                severity: "success",
            })
            setIsBurningFlag(true)
        } catch (error) {
            setIsBurningFlag(true)
            setAlertState({
                open: true,
                message: "Burn Token Failed",
                severity: "error",
            })
        }
    }


    return (
        <Box>
            <Stack
                paddingX={'40px'}
                paddingY={'20px'}
                direction="row"
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Stack direction="row" spacing={2}>
                    <a href="https://t.me/popiwtf">
                        <img src={telegram} alt="" height={'70%'} />
                    </a>
                    <a href="https://twitter.com/popiwtf">
                        <img src={skype} alt="" height={'70%'} />
                    </a>
                </Stack>

                <Box>
                    {wallet ?
                        <WalletMultiButton /> :
                        <WalletMultiButton>Connect Wallet</WalletMultiButton>}
                </Box>

            </Stack>
            <div style={{ fontFamily: 'Mainfont', display: "flex", justifyContent: "center", textAlign: "center", alignContent: "center" }}>
                <div style={{ marginTop: "5%", width: "50%" }}>
                    {
                        tokenAdressFlag ?
                            <div style={{ marginTop: "70px" }}>
                                <Input style={{ border: "0", color: "black", width: "60%", fontFamily: 'Mainfont', fontSize: "1.5em" }} autoFocus onChange={((e: any) => {
                                    setTokenAdress(e.target.value);
                                    if (e.target.value === "") setTokenAdressFlag(false)
                                })} className="placeholder-white" /> </div> :
                            <StyledTypography style={{ color: "black", marginTop: "70px" }} className="token-name" ><div style={{ marginTop: "70px", fontSize: "1.5em", fontFamily: 'Mainfont' }} onClick={() => {
                                setTokenAdressFlag(true)
                                console.log(swoleAmount)
                                if (!swoleAmount) setTokenAmountFlag(false)
                            }}>INPUT TOKEN ADDRESS</div></StyledTypography>

                    }
                    {
                        tokenAmountFlag ?
                            <div style={{ marginTop: "70px" }}><Input style={{ border: "0", width: "60%", color: "black", fontSize: "1.5em", fontFamily: "Mainfont" }} autoFocus onChange={((e: any) => {
                                setSwoleAmount(e.target.value);
                                if (e.target.value === "") setTokenAmountFlag(false)
                            })} className="placeholder-white" /> </div> :
                            <StyledTypography className="token-name" style={{ color: "black", marginTop: "70px" }} ><div style={{ marginTop: "70px", fontFamily: 'Mainfont', fontSize: "1.5em" }} onClick={() => {
                                setTokenAmountFlag(true)
                                console.log(tokenAdress)
                                if (!tokenAdress) setTokenAdressFlag(false)
                            }}>INPUT BURN AMOUNT</div></StyledTypography>

                    }
                    <div style={{ marginTop: "50px" }}>
                        {isConnected ?
                            isBurningFlag ? <button style={{ background: "none", fontFamily: 'Mainfont', color: "black", outline: "none", border: "none", fontWeight: "bold", fontSize: "2em" }} onClick={burnToken} >  INITIATE BURN </button> :
                                <button style={{ background: "none", fontFamily: 'Mainfont', color: "black", outline: "none", border: "none", fontWeight: "bold", fontSize: "2em" }}   >  Please wait ... </button>
                            : <></>
                        }
                    </div>
                </div>
                <div style={{ display : "inline-block",position: "absolute", bottom : "0px", left : "0px", width : "35%"}}>
                    <img src={Awful} style={{ width: "100%" , float : "left"}} alt="" />
                </div>
            </div>

            {/* <Snackbar
                open={alertState.open}
                autoHideDuration={6000}
                onClose={() => setAlertState({ ...alertState, open: false })}
            >
                <Alert
                    onClose={() => setAlertState({ ...alertState, open: false })}
                    severity={alertState.severity}
                >
                    {alertState.message}
                </Alert>
            </Snackbar> */}
        </Box >
    );
};

export default Home;
